import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import './CaabItem.scss';
import Utils from '../../../../utils/utils';

class CaabItem extends PureComponent {
  render() {
    const { item, intl } = this.props;

    return (
      <div className="caab__item">
        <div className="caab__item__icon">
          <img
            alt={item.title}
            src={item.icon}
          />
        </div>
        <div className="caab__item__text">{intl.formatMessage({id: item.title})}</div>
        <div className="caab__item__subText">
          <span
            dangerouslySetInnerHTML={{
              __html: Utils.bbCodeToHtml(intl.formatMessage({id: item.text}))
            }}
          />
        </div>
      </div>
    );
  }
}

CaabItem.propTypes = {
  item: PropTypes.object,
  intl: PropTypes.object,
};

export default injectIntl(CaabItem);
