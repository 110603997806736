import React from 'react';
import Layout from '../components/Layout';
import { withIntl } from '../i18n';
import CaaBPage from '../templates/CaaB';

import {
  cloudCaab,
  apiCaab,
  light,
  activity,
  ring,
  button,
  image,
} from '../images';

const caab = {
  header: {
    title: 'caabHeaderTitle',
    subTitle: 'caabHeaderSubTitle'
  },
  image,
  list: [
    {title: 'caabCloudTitle', icon: cloudCaab, text: 'caabCloudText'},
    {title: 'caabApiTitle', icon: apiCaab, text: 'caabApiText'},
    {title: 'caabEventsTitle', icon: light, text: 'caabEventsText'},
    {title: 'caabDashbordsTitle', icon: activity, text: 'caabDashbordsText'},
    {title: 'caabEscalateTitle', icon: ring, text: 'caabEscalateText'},
    {title: 'caabDeployTitle', icon: button, text: 'caabDeployText'}
  ],
  button: 'caabButton'
};

const CaaB = (props) => (
  <Layout {...props}>
    <CaaBPage
      data={caab}
    />
  </Layout>
);


export default withIntl(CaaB);
