import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Label,
} from 'mw-style-react';
import {injectIntl} from 'react-intl';
import './Caab.scss';
import CaabItem from './components/CaabItem';
import { Link } from '../../i18n';

const CaaB = (props) => {
  const {intl, data} = props;
  const {header, image, list, button} = data;

  return (
    <section className="caab">
      <div className="caab__header" />
      <div className="caab__wrap">
        <Label
          value={intl.formatMessage({id: header.title})}
          className="caab__title"
        />
        <Label
          value={intl.formatMessage({id: header.subTitle})}
          className="caab__subTitle"
        />
        <img className="caab__image" src={image} alt={'sinc'} />
      </div>
      <div className="caab__wrap">
        <div className="grid-wrapper">
          {list.map((item, index) => (
            <div className="col-4" key={index}>
              <CaabItem item={item}/>
            </div>
          ))}
        </div>
      </div>
      <div className="caab__btn__wrapper">
        <Link to={'/form/caab'} className="caab__btn">
          <Button
            className="button__default"
            label={intl.formatMessage({id: button})}
          />
        </Link>
      </div>
    </section>
  );
};

CaaB.propTypes = {
  intl: PropTypes.object,
  data: PropTypes.object,
};

export default injectIntl(CaaB);
